import Api from "../Api";

export default abstract class BaseCommand {
    protected apiInstance = Api;

    constructor() {
    }

    public getRequestBody() {
        return Object.fromEntries(Object.entries(this).filter(e => e[0] != 'apiInstance'))
    }

    public abstract getEndpoint(): string;

    public isUpdate(): boolean {
        return false;
    }
    public isDelete(): boolean {
        return false;
    }

    public isPatch(): boolean {
        return false;
    }


    public Execute() {
        if(this.isUpdate()) {
            return this.apiInstance.PutAsync(this.getEndpoint(), this.getRequestBody())
        } else if(this.isDelete()) {
            return this.apiInstance.DeleteAsync(this.getEndpoint(), this.getRequestBody())
        } else if (this.isPatch()){
            return this.apiInstance.PatchAsync(this.getEndpoint(), this.getRequestBody())
        } else {
            return this.apiInstance.PostAsync(this.getEndpoint(), this.getRequestBody())
        }
    }
}