<template>
    <div
        :class="'page-container screen--' + screen"
    >
        <v-container fluid>
            <section 
                v-if="title != null && title != undefined"
                class="toolbar toolbar--heading"
            >
                <div class="toolbar-content">
                    <div class="title-bar">
                        <h1 class="toolbar__section-title">{{title}}</h1>
                    </div>
                    <div class="theme-bar">
                        <v-switch 
                            label="Toggle theme"
                            inset
                            :value="theme.global.name" 
                            @change="toggleTheme"
                            class="theme-toggler"
                            size="large" 
                        ></v-switch>
                    </div>
                </div>
            </section>
            <slot></slot>
        </v-container>
    </div>
</template>

<script lang="ts" setup>
    import { useTheme } from 'vuetify'
    const theme = useTheme()
    function toggleTheme () { 
        theme.global.name.value = theme.global.current.value.dark ? 'defaultTheme' : 'defaultThemeDark'
        console.log(theme);
    }
</script>

<script lang="ts">
export default {
    props: {'screen': String, "title": String}
}
</script>

<style scoped>
.toolbar-content {
    display: flex;
    align-items: center;
    width: 100%;
}

.title-bar {
    flex-grow: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.toolbar__section-title {
    overflow: hidden;
    text-overflow: ellipsis;
}

.theme-bar {
    flex-shrink: 0;
    padding-left: 100px;
}
</style>