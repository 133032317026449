import BaseQuery from "../BaseQuery";

export default class GetScriptByScriptIdQuery extends BaseQuery {
    public scriptId: string;
    constructor(scriptId: string) {
        super()
        this.scriptId = scriptId
    }

    public override ConstructQueryString() : void {
        
    }
    
    public GetEndpoint(): string {
        return "conversation/script/" + this.scriptId
    }
}