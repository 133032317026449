// Maintain alphabetical order from after 'common' values.
// Indent starts new order.

const enGB = {
  language: {
    display: "English (British)",
    locale: "en-GB"
  },
  Value: "Value",
  Where: "Where",
  Operator: "Operator",
	common: {
    actions: 'Actions',
    add: 'Add',
    save: 'Save',
    addFilter: 'Add filter',
    api: 'Api',
    apply: 'Apply',
    archival: 'Archive / Unarchive',
		areYouSure: 'Are you sure?',
    authors: 'Authors',
    back: 'Back',
    cancel: 'Cancel',
    clear: 'Clear',
    clearFilter: 'Clear filter',
    close: 'Close',
    create: 'Create',
    collapse: 'Collapse',
    complete: 'Complete',
		confirm: 'Confirm',
		continue: 'Continue',
    dataType: 'Data type',
    copy: 'Copy',
		delete: 'Delete',
    description: 'Description',
		discard: 'Discard',
		edit: 'Edit',
    email: "Email address",
    expand: 'Expand',
    filter: 'Filter',
		functionalityNotReady: 'This functionality is not ready yet',
		help: 'Help',
    Kapture: 'Kapture',
    logIn: 'Log in',
    amendedBy: "Modified by",
    amendedDate: "Last modified",
    name: "Name",
    password: 'Password',
    saveChanges: 'Save changes',
    select: 'Select',
    selectAll: 'Select all',
    selectLanguage: 'Select language',
    softwareBy: 'Software by',
    userName: 'Username',
		view: 'View',
    viewMenuOptions: '*_ View menu options _*',
    createdBy: "Created by",
    createDate: "Create date",
    lastModified: "Last modified",
    modifiedBy: "Modified by",
	},
  campaign: {
    campaignName: 'Campaign name',
    additionalField: 'Enable status dropdown',
    createCampaign: 'Create campaign',
    createYourCampaign: 'Create your campaign',
    createANewCampaign: 'Create a new campaign',
    saveName: 'Save name',
    updateCampaign: 'Update campaign',
    archival: {
      archive: "Would you like to archive the selected campaign?",
      unarchive: "Would you like to unarchive the selected campaign?",
    },
    dialogs: {
      deleteCampaignTitle: "Delete campaign?",
      deleteCampaignBody: `<p>Are you sure you want to delete this campaign?</p>
                           <p>Deleting a campaign cannot be undone and also deletes its flows, forms, conditions, and variables.</p>`
    },
    errors: {
      campaignMustBeUnique: 'Campaign name must be unique',
      formNameMustBeUnique: 'Form name must be unique',
      formControlNameMustBeUnique: 'Control name must be unique',
      nameRequired: "Form name is required",
      formControlNameRequired: "Control name",
      formControlTypeRequired: "Control type",
      formControlLabelRequired: "Label",
      formControlVariableRequired: "Variable",
      variableRequired: "Variable",
      variableLowercase: "Variable",
      authorsRequired: "Campaign authors",
      conditionRequired: "Condition"
    },
    form: {
      nameRequired: "Form name"
    },
    messages: {
      createSuccess: "Successfully created campaign.",
      updateSuccess: "Successfully updated campaign."
    },
    reasons: {
      addMajorReason: 'Add major reason',
      addMinorReason: 'Add minor reason',
      updateMajorReason: 'Edit major reason',
      updateMinorReason: 'Edit minor reason',
      createReason: 'Create reason',
      majorReasons: 'Major reasons',
      minorReasons: 'Minor reasons',
      noMinors: 'No minor reasons added',
      reasonCode: 'Reason code',
      reasons: 'Reasons',
      reasonsCollapsed: 'reasons collapsed',
    },
    actions: {
      actionName: 'Action name'
    }
    
  },
  error: {
    f000: "User with this email address already exists.",
    loginCancel: `<p>Your login attempt was not completed.</p>
    <p>To log in to Advisgo, you need to be signed in to your Microsoft 365 account.</p>
    <p>Try again or contact an administrator for help.</p>`,
    contactAdmin: `<p>Advisgo cannot log you in.</p>
    <p>Please contact your Advisgo administrator for help.</p>`,
    contactAdminTitle: "Contact your administrator",
    loginTitle: "Advisgo login"
  },
  condition: {
    create: "Create condition",
    edit: "Edit condition",
    name: "Name"
  },
  calculation: {
    create: "Create computed variable",
    edit: "Edit computed variable",
    name: "Name",
    nameUnique: "Computed variable name must be unique"
  },
  formManagement: {
    formName: 'Name',
    description: 'Description',
    createdBy: 'Created by',
    lastModified: 'Last modified',
    modifiedBy: 'Modified by',
    actions: 'Actions',
    dialogs: {
      deleteFormTitle: "Delete form?",
      deleteFormBody: `<p>Are you sure you want to delete this form?</p>
                       <p>If the form is used by one or more flows, then deleting it will break those flows.</p> 
                       <p>Deleting a form cannot be undone.</p>`
    }
  },
  variableManagement: {
    variableName: 'Name',
    createdBy: 'Created by',
    createDate: 'Create date',
    dataType: 'Data type',
    lastModified: 'Last modified',
    modifiedBy: 'Modified by',
    dialogs: {
      deleteComputedTitle: "Delete computed variable",
      deleteComputedBody: `<p>Are you sure you want to delete this computed variable?</p>
                           <p> This computed variable will be deleted from forms, and any flows that currently use this computed variable will no longer publish correctly.</p>`,
      deleteInputVariableTitle: "Delete input variable",
      deleteInputVariableBody: `<p>Are you sure you want to delete this input variable?</p>
                                <p> Any flows that currently use this input variable, will no longer publish correctly.</p>`
    },
    errors: {
      variableNameRequired: "Name",
      variableDataTypeRequired: "Data type"
    }
  },
  flowManagement: {
    name: "Flow name",
    createdBy: "Created by",
    lastModified: "Last modified",
    modifiedBy: "Modified by",
    lastPublished: "Last published",
    actions: "Actions",
    errors: {
      nameRequired: 'Flow name',
      flowNameMustBeUnique: "Flow name must be unique"
    }
  },
  flowBuilder:{
    dialogs:{
      changeFormTitle: "Change form?",
      changeFormBody: "Are you sure you want to change the form for this node?"
    }
  },
  formBuilder: {
    addControl: 'Add control',
    selectControl: 'Select control',
  },
  node: {
    valueInput: "Value",
    variableValueInput: "Test value",
    useToday: "Current date"
  },
  login: {
    accountLocked: "Account is locked!",
    incorrectDetails: 'Your details are incorrect',
    invalidLogin: "Invalid username or password!",
    invalid2faCode: "Invalid two-factor authentication code!",
  },
  loginPage: {
    buttonLogin: "Sign in",
    caption: "Welcome",
    continueYourJourney: "Continue your journey with Kapture",
    emailPlaceholder: "yourname{'@'}domain.com",
    forgotPasswordLink: "Forgot password?",
    passwordField: "Password",
    selectLanguage: "Select Language",
    summary: "Advisgo is a dynamic, no-code workflow tool.",
    summarySub: "Log in to get started!",
    usernameField: "E-mail address",
    welcome: "Welcome!"
  },
  groups: {
    groupName: "Group name",
    home: {
      createNewGroup: "Create group",
      columns: {
        groupName: "Group name",
        campaigns: "Campaigns",
        users: "Users",
        lastModified: "Last modified"
      
      }
    },
    errors: {
      groupNameMustBeUnique: "Group name must be unique"
    }
  },
  navigation: {
    administration: "Administration",
    campaigns: 'Campaigns',
    campaignManagement: 'Campaign managment',
    userAccountManagement: "User Management",
    campaignOverview: 'Campaign overview',
    createCampaign: "Create Campaign",
    dashboard: 'Dashboard',
    home: "Home",
    help: 'Help',
    kapture: "Kapture records",
    listings: "Tenant listings",
    logout: 'Log out',
    question: "Question of the day",
    styleGuide: "Style guide",
    userManagement: 'Admin users',
    users: "Users",
    groupManagement: 'Group management',
    groups: "Groups",
    flowRunner: 'Flow Runner',
    goToRuntime: 'Go to Runtime',
    goToArchitect: 'Go to Architect'
  },
  properties: {
    labelType: "Type of label",
    label: "Label",
    labelRequired: "Label (Required)",
    hideLabel: "Hide label on Flow Runner",
    subLabel: "Sub-label",
    displayTime: 'Include time',
    visible: "Visible",
    conditionalVisible: "Visibility condition",
    numberRows: "Number of rows",
    readonly: "Read-only",
    conditionalReadonly: "Read-only condition",
    maxCharacters: "Maximum number of characters",
    defaultValue: "Default value",
    required: "Required",
    conditionalRequired: "Condition for Required",
    variable: "Variable",
    variableRequired: "Variable (Required)",
    addSelectItem: "Add items",
    selectDefaultItem: "Select an item to make it the default value:",
    addSelectItemDialogTitle: "Add items",
    selectitemKey: "Display as",
    selectitemValue: "Value",
    urlLink: "URL address",
    multiline: "Multi-line text box",
    dataType: "Data Type"
  },
  passwordChange: {
    cancelButton: "Cancel",
    changePassword: 'Change password',
    enterNewPassword: 'Enter new password',
    failedChange: 'Failed to change user password!',
    passwordChange: 'Password change',
    passwordCreate: 'Create password',
    passwordChangeSuccessTitle: "You have activated your account",
    passwordChanged: "You have successfully changed your Advisgo account password.",
    passwordHint: 'Passwords must contain at least: \n\n \u2022 {minLength} characters in length \n \u2022 {upperCaseCharacters} uppercase letter \n \u2022 {lowerCaseCharacters} lowercase letter \n \u2022 {specialCharacters} special character \n \u2022 {numericCharacters} number',
    pleaseLogin: "Log in to get started!",
    returnToLoginButton: "Log in", 
  },
  recovery: {
    buttonCloseInvalid: "Close",
    buttonCreatePasswordConfirmed: "Create password",
    captionConfirm: "Confirm your username",
    request: 'Send',
    title: 'Reset your password',
    subheading: 'Enter your username and select Send. You will receive an email with a link to reset your password. ',
    captionConfirmed: "Email address confirmed",
    captionInvalid: "Email address not confirmed",
    captionVerifying: "Welcome to Advisgo",
    messageConfirmed: "Success! Your email address has been confirmed. To complete your account setup, you need to create a password.",
    messageInvalid_p1: "Sorry, it looks like your activation link has expired or you already have an Advisgo account.",
    messageInvalid_p2: "If your link has expired, you need to contact Advisgo's administrator. You can find their contact details in the activation email. If you already have an account, you need to close this window and log in from the home page.",
    messageVerifying: "Your email address is now being confirmed. This will only take a few seconds. Do not close this window.",
  },
  role: {
		Administrator: 'Administrator',
		Author: 'Author',
    EndUser: 'End user',
		Viewer: 'Viewer',
  },
  twoFactorAuth: {
    authCode: "Authenticator code",
    cancelButton: "Cancel",
    cancelTwoFactorCreateButton: "Cancel",
    createTwoFactorAuth: 'Enable two-step verification',
    instructions: "Please use Google Authenticator application on your mobile device and scan the QR code below or enter the code manually. If you do not have the application please visit application store and install application Google Authenticator before proceeding.",
    instructionsP1: "To complete the setup of your Advisgo account, you need to enable two-step verification. You can do that by adding Advisgo to Google Authenticator on your mobile device using the QR code or the setup key provided below.",
    instructionsP2: "After you have set up two-step verification you can log in to Advisgo using your password and a code provided by Google Authenticator.",
    instructionsP3: "If you don`t have Google Authenticator, download it to your mobile device now from Google Play or the App Store.",
    twoFactorAuth: 'Two-step verification',
    setupKey: "Setup key",
    sixDigitCode: "Enter your Google Authenticator 6-digit code:",
    verifictionCode: "Please enter 6-digit code from Authenticator application",
    verifyCode: 'Verify code',
  },
  user: {
    create: {
			emailAddress: 'Email address',
			firstName: 'First name',
			inviteUser: 'Invite user',
			knownAs: 'Known as',
			lastName: 'Last name',
			selectRole: 'Select role',
			title: 'Create user',
		},
    edit: {
      title: 'Edit user',
    },
    delete: {
      title: 'Delete user?',
    },
		home: {
			activationLinkExpired: 'Activation link expired',
			assignRoles: 'Assign roles',
			backToDashboard: 'Back to dashboard',
			bulkActions: 'Bulk actions',
			columns: {
        actions: 'Actions',
				email: 'Email address',
				knownAs: 'Known as',
				lastActive: 'Last active',
				name: 'Name',
				role: 'Role',
			},
			createNewUser: 'Create user',
      deleteUserEmail:'{emailAddress}',
			deleteUserMessage: 'Are you sure you want to delete the following user?',
			existingUsers: 'Existing users',
			myProfile: 'My profile',
			resendInvite: 'Resend invite',
      reset2FA: 'Reset two-factor authentication',
			searchUsers: 'Search users',
			title: 'Manage admin users',
			unregistered: 'Unregistered',
      endFlow: 'End flow',
      exitFlow: 'Exit flow',
      dialogs:{
        reset2FATitle: 'Reset 2FA?',
        reset2FABody: 'Are you sure you want to reset two-factor authentication (2FA) for {name}?',
        reset2FASuccessBody: `<p>Two-factor authentication has been reset for {name}.</p>
                              <p>When they next log in, they will be taken through steps to set up 2FA.</p>`,
        reset2FAFailureBody: `<p>Unable to reset two-factor authentication for, {name}.</p>
                              <p>Contact support.</p>`
      }
		},
    myProfile: {
      title: 'My Profile'
    },
    resendInvite: {
      title: 'Resend invite',
      message: 'Send invite to the following email address?'
    },
		roles: {
			administrator: 'Administrators ({count})',
			all: 'All ({count})',
			author: 'Authors ({count})',
			viewer: 'Viewers ({count})',
		},
	},
  validation: {
    isNotValid: '{name} is not valid',
    isRequired: '{name} is required',
    shouldBeEqual: '{name} should be equals {equalName}',
    shouldBeMax: '{name} should be less than {max} characters',
    shouldBeMin: '{name} should be more than {min} characters',
    shouldBeMinMax: '{name} should be between {min} and {max}',
    password: {
      lowerCaseCharacters: '{name} must include {lowerCaseCharacters} lowercase letter(s)',
      minLength: '{name} must be {minLength} characters in length',
      numericCharacters: '{name} must include {numericCharacters} number(s)',
      specialCharacters: '{name} must include {specialCharacters} special character(s)',
      upperCaseCharacters: '{name} must include {upperCaseCharacters} uppercase letter(s)',
    },
  }, 
}

export default enGB;
