import BaseCommand from "../BaseCommand";

export default class CreateConversationReportCommand extends BaseCommand {
    public tenantId: string
    public agentId: string
    public agentName: string
    public startDateTime: string
    public endDateTime: string
    public campaignId: string
    public campaignName: string
    public conversationId: string
    public conversationName: string
    public abandoned: string

    constructor() {
        super()
        this.tenantId = ""
        this.agentId = ""
        this.agentName = ""
        this.startDateTime = ""
        this.endDateTime = ""
        this.campaignId = ""
        this.campaignName = ""
        this.conversationId = ""
        this.conversationName = ""
        this.abandoned = ""
    }

    public getEndpoint(): string {
        return  "conversation/reporting/conversation"
    }
}