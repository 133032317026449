import { mergeProps } from 'vue';


// {
//   path: "style-guide",
//   name: 'architect-guide',
//   component: () => import('@/views/Runtime/StyleGuide/StyleGuide.vue'),
//   meta: {
//     displayName: "navigation.styleGuide",
//     icon: "$paletteSwatchOutline",
//   },
// },

export default [
  {
    path: 'user-management',
    component: () => import('@/views/Architect/user-managment/Home.vue'),
    name: 'User',
    meta: {
      groups: ["Administrator"],
      displayName: "navigation.users",
      icon: "$accountMultiple",
    },
    props: true,
  },
  {
    path: 'group-management',
    component: () => import('@/views/Architect/group-management/Home.vue'),
    name: 'Group',
    meta: {
      groups: ["Administrator"],
      displayName: "navigation.groups",
      icon: "$folderAccount",
    },
    props: true,
  },
  {
    path: 'campaign-management',
    name: '',
    props: true,
    meta: {
      groups: ["Administrator", "Author"],
      displayName: "navigation.campaigns",
      icon: "$folderOpen",
    },
    children: [
      {
        path: '',
        name: 'campaign-management',
        component: () => import('@/views/Architect/campaign-management/Campaigns.vue'),
        props: true,
      },
      {
        path: ':campaignId',
        props: true,
        children: [
          {
            path: '',
            name: 'campaign-overview',
            component: () => import('@/views/Architect/campaign-management/Campaign.vue'),
            props: true
          },
          {
            path: 'actions/:actionName',
            name: 'action-builder',
            component: () => import('@/views/Architect/action-builder/Home.vue'),
            props: true
          },
          {
            path: 'form-builder/:formId',
            name: '',
            meta: {
              displayName: "Form builder this needs moved",
              icon: "$homeVariant",
            },
            children: [
              {
                component: () => import('@/views/Architect/form-management/Form.vue'),
                path: "",
                name: 'form-builder', 
              },
              {
                path: "property-inspector/:controlId",
                name: 'property-inspector',
                component: () => import('@/views/Architect/form-management/PropertiesInspector.vue'),
                props: true
              }
            ],
            props: true
          },
          {
            path: 'form-management',
            name: '',
            children: [
              {
                component: () => import('@/views/Architect/form-management/Forms.vue'),
                path: "",
                name: 'form-management', 
              }
            ]
          },
          {
            path: 'flow-builder',
            name: '',
            children: [
              // {
              //   component: () => import('@/views/Architect/flow-management/Flows.vue'),
              //   path: "",
              //   name: 'flow-management',  
              //   props: true
              // },
              {
                path: ":flowId",
                component: () => import('@/views/Architect/flow-management/FlowBuilder.vue'),
                name: 'flow-builder', 
                props: true
              }
            ]
          },
          {
            path: 'variable-management',
            name: '',
            children: [
              {
                component: () => import('@/views/Architect/variable-management/Variables.vue'),
                path: ":campaignName",
                name: 'variable-management',
                props: true
              }
            ]
          },
        ]
      }
    ]
  }
]