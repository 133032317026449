import BaseQuery from "../BaseQuery";

export default class GetFormByFormIdQuery extends BaseQuery {
    public formId: string;
    constructor(formId: string) {
        super()
        this.formId = formId
    }

    public override ConstructQueryString() : void {
        
    }
    
    public GetEndpoint(): string {
        return "conversation/form/" + this.formId
    }
}