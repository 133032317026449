import { defineStore } from 'pinia'
import Api from '@/common/api/Api';
import { useScriptDesignStore } from './scriptDesign';
import { useFlowDataStore } from './flowData';

export const useAuthenticationStore = defineStore({
  id: 'authentication',
  state: () => ({
    user: null as any | null,
    directives: [] as string[],
    tokens: null as any | null,
    tokenExpiry: null as any,
    tokenValid: false as boolean,
    tenant: '' as string
  }),
  getters: {
    isAuthenticated: (state) => {
      return state.user != null && state.tokens != null
    },
    getTenantId: (state) => {
      return state.user?.tenantId
    },
    getUserGroup: (state) => {
      return state.user?.role
    },
    getUserId: (state) => {
      return state.user?.userId
    },
    getTokens: (state) => {
      return state.tokens
    },
    getTokenExpiry: (state) => {
      return state.tokenExpiry
    },
    isTokenValid: (state) => {
      return state.tokenValid
    },
    getTimezone: (state) => {
      var timeZone = state.user?.timezone
      if(timeZone == null || timeZone.includes("GMT")) {
        return "Europe/London"
      }
      return "Africa/Johannesburg"
    },
    hasDirective: (state) => {
      return (directive: string) => (state.directives.findIndex((x: string) => x.toLowerCase() == directive.toLowerCase()) >= 0);
    },
    getTenant: (state) => {
      return state.tenant
    }
  }, 
  actions: {
    getTokenExpirySeconds() {
      if(this.tokens != null && this.tokens.tokenExpiry != null) {
        var expiryDateTime = new Date(this.tokens.tokenExpiry).getTime()
        var nowDateTime = new Date().getTime()
        return Number.parseInt(((expiryDateTime - nowDateTime) / 1000).toFixed(0))
      }
      return 0
    },
    setToken(token: string) {
      this.tokens = token
    },
    setTokenValid(isValid: boolean) {
      this.tokenValid = isValid
    },
    setDirectives(directives: string[]) {
      this.directives = directives
    },
    removeDirective(directive: string) { this.directives = this.directives.filter((x: string) => x.toLowerCase() != directive.toLowerCase()); },
    async logIn(username: String, password: String, successCallback: any, failureCallback: any) {
      try {
				var response = await Api.signin(username, password, this.tenant) as any;
        
        if (response == null) {
          failureCallback(null)
        }

        if(response.responseStatus < 2) {
          this.user = response.userModel
          this.tokens = response.tokens
          this.directives = response.directives
          successCallback(response)
        } else {
          failureCallback(response.responseMessage)
        }
      } catch(error) {
        failureCallback(error)
      }
    },
    setTenant(tenant: string) {
      this.tenant = tenant
    },
    async logOut() {
      await Api.signout(this.getUserId) as any;
      const scriptStore = useScriptDesignStore()
      const flowStore = useFlowDataStore()
      scriptStore.reset()
      flowStore.resetStore()
      this.user = null
      this.tokens = null
      this.directives = []
      this.tokenValid = false
    }
  },
  persist: true
})
