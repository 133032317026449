import { mdiEye, mdiEyeOff, mdiTrashCan } from "@mdi/js";

    export const ConfirmDeleteModal = (context: any, callback: any): void => {
        context.$swal.fire({
            title: "Are you sure?",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
                container: 'i-alert',
            },
            showClass: {
                popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
                `
            },
            hideClass: {
                popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
                `
            }
          }).then(callback);
    }

    export const ConfirmVisibilityModal = (
        context: any, 
        message: string, 
        callback: any, 
        html: any, 
        title: string, 
        buttonText: string,
        iconButton: string
    ): void => {
        context.$swal.fire({
            html: `
                <div class="swal2-title-with-icon">
                    <div class="swal2-icon-default">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                            <path d="${iconButton}" />
                        </svg>
                    </div>
                    <h2>${title}</h2>
                </div>
                <div class="p-warnings">
                    <div>
                        ${html}
                    </div>
                </div>
            `,
            text: message,
            confirmButtonText: buttonText,
            showCancelButton: true,
            customClass: {
                container: 'i-alert',
                popup: 'swal2-popup-custom',
                title: 'swal2-title',
            },
            showClass: {
                popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster`
            },
            hideClass: {
                popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster`
            }
        }).then((result: any) => {
            if (result.isConfirmed) {
                callback();
            }
        });
    };

    export const ConfirmDeletionModal = (
        context: any, 
        message: string, 
        callback: any, 
        html: any, 
        title: string, 
        buttonText: string
    ): void => {
        context.$swal.fire({
            html: `
                <div class="swal2-title-with-icon">
                    <div class="swal2-icon-custom">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                            <i class="${mdiTrashCan}" />
                        </svg>
                    </div>
                    <h2>${title}</h2>
                </div>
                <div class="p-warnings">
                    <div>
                        ${html}
                    </div>
                </div>
            `,
            text: message,
            confirmButtonText: buttonText,
            showCancelButton: true,
            customClass: {
                container: 'i-alert i-alert--error',
                popup: 'swal2-popup-custom',
                title: 'swal2-title',
            },
            showClass: {
                popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster`
            },
            hideClass: {
                popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster`
            }
        }).then((result: any) => {
            if (result.isConfirmed) {
                callback();
            }
        });
    };
    export const ConfirmDeleteMessageModal = (context: any, message: string, callback: any): void => {
        context.$swal.fire({
            title: "Are you sure?",
            text: message,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
                container: 'i-alert i-alert--error',
            },
            showClass: {
                popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
                `
            },
            hideClass: {
                popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
                `
            }
          }).then((result: any) => {
            if(result.isConfirmed) {
                callback()
            }
          });
    }
    export const ConfirmBackModal = (context: any, callback: any): void => {
        context.$swal.fire({
            title: "Are you sure?",
            text: "Any unsaved data will be lost!",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
                container: 'i-alert',
            },
            showClass: {
                popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
                `
            },
            hideClass: {
                popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
                `
            }
          }).then(callback);
    }
    export const ConfirmSucceededModal = (context: any, message: string, callback: any): void => {
        context.$swal.fire({
            title: "Success!",
            html: message,
            confirmButtonText: "Close",
            customClass: {
                container: 'i-alert i-alert--success',
            },
            showClass: {
                popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
                `
            },
            hideClass: {
                popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
                `
            }
          }).then(callback);
    }

    export const ConfirmErrorModal = (context: any, message: string, callback: any): void => {
        context.$swal.fire({
            title: "Error",
            html: message,
            customStyle: {
                width: "auto"
            },
            customClass: {
                container: 'i-alert i-alert--error',
            },
            showClass: {
                popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
                `
            },
            hideClass: {
                popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
                `
            }
          }).then(callback);
    }

    export const ConfirmErrorModalTitle = (context: any, title: string, message: string, callback: any): void => {
        context.$swal.fire({
            title: title,
            html: message,
            customStyle: {
                width: "auto"
            },
            customClass: {
                container: 'i-alert i-alert--error',
            },
            showClass: {
                popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
                `
            },
            hideClass: {
                popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
                `
            }
          }).then(callback);
    }

    
    export const ConfirmArchiveModal = (context: any, message: string, callback: any): void => {
        context.$swal.fire({
            title: "Are you sure?",
            html: message,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
                container: 'i-alert',
            },
            showClass: {
                popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
                `
            },
            hideClass: {
                popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
                `
            }
          }).then((result: any) => {
            if(result.isConfirmed) {
                callback()
            }
          });
    }

    export const ConfirmModal = (context: any, title: String, message: string): void => {
        context.$swal.fire({
            title: title,
            html: message,
            confirmButtonText: "Confirm",
            customClass: {
                container: 'i-alert i-alert--rich',
            },
            showClass: {
                popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
                `
            },
            hideClass: {
                popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
                `
            }
          });
    }

    export const ConfirmCancelModal = (context: any, title: String, message: string): void => {
        return context.$swal.fire({
            title: title,
            html: message,
            confirmButtonText: "Confirm",
            showCancelButton: true,
            customClass: {
                container: 'i-alert v-dialog--default',
            },
            showClass: {
                popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
                `
            },
            hideClass: {
                popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
                `
            }
        });
    }

    export default {ConfirmDeleteModal, ConfirmBackModal,ConfirmErrorModalTitle, ConfirmSucceededModal, ConfirmModal, ConfirmDeleteMessageModal, ConfirmCancelModal, ConfirmErrorModal, ConfirmVisibilityModal}