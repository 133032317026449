import Api from "../Api";

export default abstract class BaseQuery {
    protected apiInstance = Api;
    
    private queryString: string = '';

    constructor() {
    }

    public isPost(): boolean {
        return false;
    }

    public abstract GetEndpoint(): string;

    public addQueryString(key: string, value: string): void {
        if (this.queryString !== '') {
          this.queryString += '&';
        }
        this.queryString += `${key}=${encodeURIComponent(value)}`;
    }

    public getRequestBody() {
        return Object.fromEntries(Object.entries(this).filter(e => e[0] != 'apiInstance'))
    }

    public abstract ConstructQueryString(): void;
    
    public GetQueryString(): string | null {
        this.queryString = ""
        this.ConstructQueryString()
        return this.queryString;
    }
    
    public async Execute() {
        if (this.isPost()) {
            return this.apiInstance.PostAsync(this.GetEndpoint(), this.getRequestBody())
        } else {
            const queryStringArguments = this.GetQueryString()

            return await this.apiInstance.GetAsync(this.GetEndpoint() + 
                (queryStringArguments != null? 
                    ("?" + this.GetQueryString()) 
                    : ''), 
                this)
        }
    }
}