import BaseCommand from "../BaseCommand";

export default class TestFlowCommand extends BaseCommand {
    public flowId: string
    public campaignId: string;

    constructor(campaignId: string, flowId: string) {
        super()
        this.flowId = flowId;
        this.campaignId = campaignId
    }

    public getEndpoint(): string {
        return "conversation/flow/test"
    }
}