import AboutAdvisgo from '@/assets/help/about-advisgo.htm?raw'
import UserAccounts from '@/assets/help/user-accounts/user-accounts.htm?raw'
import Login from '@/assets/help/user-accounts/log-in.htm?raw'
import ActivateAccount from '@/assets/help/user-accounts/activate-account.htm?raw'
import ForgottenPassword from '@/assets/help/user-accounts/forgotten-password.htm?raw'
import LockedAccount from '@/assets/help/user-accounts/locked-account.htm?raw'
import CreateUser from '@/assets/help/user-accounts/create-user.htm?raw'
import ResendInvite from '@/assets/help/user-accounts/resend-invite.htm?raw'
import Resend2FAEmail from '@/assets/help/user-accounts/resend-2fa-email.htm?raw'
import EditUser from '@/assets/help/user-accounts/edit-user.htm?raw'
import DeleteUser from '@/assets/help/user-accounts/delete-user.htm?raw'
import FlowRunner from '@/assets/help/flow-runner/flow-runner.htm?raw'
import ManageCampaigns from '@/assets/help/manage-campaigns/manage-campaigns.htm?raw'
import CreateCampaign from '@/assets/help/manage-campaigns/create-campaign.htm?raw'
import EditCampaign from '@/assets/help/manage-campaigns/edit-campaign.htm?raw'
import DeleteCampaign from '@/assets/help/manage-campaigns/delete-campaign.htm?raw'
import VariablesManager from '@/assets/help/manage-campaigns/variables-manager.htm?raw'
import FlowBuilder from '@/assets/help/flow-builder/flow-builder.htm?raw'
import CreateFlow from '@/assets/help/flow-builder/create-flow.htm?raw'
import DeleteFlow from '@/assets/help/flow-builder/delete-flow.htm?raw'
import EditFlow from '@/assets/help/flow-builder/edit-flow.htm?raw'
import CreateExitConditions from '@/assets/help/flow-builder/create-exit-conditions.htm?raw'
import TestPublishFlow from '@/assets/help/flow-builder/test-publish-flow.htm?raw'
import FormBuilder from '@/assets/help/form-builder/form-builder.htm?raw'
import CreateForm from '@/assets/help/form-builder/create-form.htm?raw'
import EditForm from '@/assets/help/form-builder/edit-form.htm?raw'
import DeleteForm from '@/assets/help/form-builder/delete-form.htm?raw'
import FormControls from '@/assets/help/form-builder/form-controls.htm?raw'
import ConditionBuilder from '@/assets/help/form-builder/condition-builder.htm?raw'
import ManageGroups from '@/assets/help/manage-groups/manage-groups.htm?raw'
import CreateGroup from '@/assets/help/manage-groups/create-group.htm?raw'
import EditGroup from '@/assets/help/manage-groups/edit-group.htm?raw'
import DeleteGroup from '@/assets/help/manage-groups/delete-group.htm?raw'
import UserRoles from '@/assets/help/user-accounts/user-roles.htm?raw'
import Glossary from '@/assets/help/glossary.htm?raw'
import SupportDesk from '@/assets/help/support/support-desk.htm?raw'

export default [
    {
        id: "advisgo-overview",
        title: "About Advisgo",
        html: AboutAdvisgo
    },
    {
        id: "user-accounts",
        title: "User accounts",
        html: UserAccounts,
        children: [
            {
                id: "activate-account",
                title: "Activate your account",
                html: ActivateAccount
            },
            {
                id: "log-in",
                title: "Login",
                html: Login
            },
            {
                id: "forgotten-password",
                title: "Forgotten password",
                html: ForgottenPassword
            },
            {
                id: "locked-account",
                title: "Locked account",
                html: LockedAccount
            },
            {
                id: "create-user",
                title: "Create a user",
                html: CreateUser
            },
            {
                id: "resend-invite",
                title: "Resend an invite",
                html: ResendInvite
            },
            // {
            //     id: "resend-2fa-email",
            //     title: "Resend 2FA email",
            //     html: Resend2FAEmail
            // },
            {
                id: "edit-user",
                title: "Edit a user",
                html: EditUser
            },
            {
                id: "delete-user",
                title: "Delete a user",
                html: DeleteUser
            }
        ]
    },
    {
        id: "flow-runner",
        title: "Flow Runner",
        html: FlowRunner
    },
    {
        id: "manage-campaigns",
        title: "Manage campaigns",
        html: ManageCampaigns,
        children: [
            {
                id: "create-campaign",
                title: "Create a campaign",
                html: CreateCampaign
            },
            {
                id: "edit-campaign",
                title: "Edit a campaign",
                html: EditCampaign
            },
            {
                id: "delete-campaign",
                title: "Delete a campaign",
                html: DeleteCampaign
            },
            {
                id: "variables-manager",
                title: "Variables manager",
                html: VariablesManager
            }
        ]
    },
    {
        id: "flow-builder",
        title: "Flow builder",
        html: FlowBuilder,
        children: [
            {
                id: "create-flow",
                title: "Create a flow",
                html: CreateFlow
            },
            {
                id: "edit-flow",
                title: "Edit a flow",
                html: EditFlow
            },
            {
                id: "delete-flow",
                title: "Delete a flow",
                html: DeleteFlow
            },
            {
                id: "create-exit-conditions",
                title: "Create exit conditions",
                html: CreateExitConditions
            },
            {
                id: "test-publish-flow",
                title: "Test and publish a flow",
                html: TestPublishFlow
            },
            
        ]
    },
    {
        id: "form-builder",
        title: "Form builder",
        html: FormBuilder,
        children: [
            {
                id: "create-form",
                title: "Create a form",
                html: CreateForm
            },
            {
                id: "edit-form",
                title: "Edit a form",
                html: EditForm
            },
            {
                id: "delete-form",
                title: "Delete a form",
                html: DeleteForm
            },
            {
                id: "form-controls",
                title: "Form controls",
                html: FormControls
            },
            {
                id: "condition-builder",
                title: "Condition builder",
                html: ConditionBuilder
            }
        ]
    },
    {
        id: "manage-groups",
        title: "Manage groups",
        html: ManageGroups,
        children: [
            {
                id: "create-group",
                title: "Create a group",
                html: CreateGroup
            },
            {
                id: "edit-group",
                title: "Edit a group",
                html: EditGroup
            },
            {
                id: "delete-group",
                title: "Delete a group",
                html: DeleteGroup
            }
        ]
    },
    {
        id: "user-roles",
        title: "User roles",
        html: UserRoles
    },
    {
        id: "glossary",
        title: "Glossary",
        html: Glossary
    },
    {
        id: "support-desk",
        title: "Support desk",
        html: SupportDesk
    } 
]