const settings = {
  _gatewayUrl: "VITE_APP_GATEWAY_PLACEHOLDER",
  _showExpressions: "VITE_APP_SHOW_EXPRESSIONS",

  getGatewayUrl: (): string => {
    if (settings._gatewayUrl.startsWith("VITE_APP")) {
      // Fetch from environment //
      settings._gatewayUrl = import.meta.env.VITE_APP_GATEWAY;
    }
    return settings._gatewayUrl;
  },

  showExpressions: (): boolean => {
    if (settings._showExpressions.startsWith("VITE_APP")) {
      console.log("_showExpressions", settings._showExpressions)
      console.log("_showExpressions", import.meta.env)
      settings._showExpressions = import.meta.env.VITE_APP_SHOW_EXPRESSIONS;
    }
    return settings._showExpressions == "true";
  }
}

export default settings;
