import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { iFlowVariable } from './api/flowInterfaces';
import { ConfirmModal } from '@/common/alerts';
import CreateConversationReportCommand from '@/common/api/commands/Reporting/CreateConversationReportCommand';
import { useScriptDesignStore } from '@/stores/scriptDesign';
import { useAuthenticationStore } from '@/stores/authentication';
import { useRouter } from 'vue-router';

export const useFlowDataStore = defineStore('flowData', () => {

    const flowId = ref('');
    const flowRunId = ref('');
    const flowVariables = ref<iFlowVariable[]>([]);
    const computedVariables = ref<any>();
    const lastChange = ref({name: '', time: 0})
    const flowStartDate = ref(new Date())
    const flowEndDate = ref(new Date())
    const router = useRouter();
    const isEndFlowTriggered = ref(false); 

    function setFlowAbandonedTrigger(value: boolean) {
        isEndFlowTriggered.value = value;
    }

    function startNewRun(flowData: any) {
        flowStartDate.value = new Date();
        flowId.value = flowData.start;
        flowRunId.value = crypto.randomUUID();
        flowVariables.value = flowData.state.map((variable) => ({name: variable.name, type: variable.type, value: null})) ?? [];
        computedVariables.value = flowData.computed ?? [];
        // variables.value = flowData.state
        
        lastChange.value = {name: '', time: 0};
    }

    function getFlowVariable(variableName : string, forReplace: boolean = false): any {
        if(variableName == undefined || variableName == null) return ""
        if(flowVariables == undefined 
            || flowVariables == null 
            || flowVariables.value == undefined 
            || flowVariables.value == null
            || flowVariables.value.length == 0) 
        {
            return `{{variable.${variableName.toLowerCase()}}}`
        }
        // console.log("getting - " + variableName)
        const variable = flowVariables.value.find((item) => item.name === variableName.toLowerCase());
        if (variable != null && variable != undefined && variable.value != null) 
        {
            switch(variable.type) {
                case "date":
                    if(forReplace)
                    {
                        //TODO DATE LOCALISATION
                        return `new Date('${variable.value}')`
                    }
                    return variable.value
                case "string":
                    if(forReplace)
                    {
                        return `'${variable.value}'`
                    }
                    return variable.value.toString()
                default:
                    return variable.value
            }
        }
    
        return null;
    }

    function getComputedVariable(variableName : string) {
        if(computedVariables == undefined 
            || computedVariables == null 
            || computedVariables.value == undefined 
            || computedVariables.value == null
            || computedVariables.value.length == 0
            || computedVariables.value.filter(c => c.name == variableName).length == 0) 
        {
            return `{{computed.${variableName}}}`
        }
        var computeds = computedVariables.value.filter(c => c.name == variableName)
        var computed = computeds[0]
        console.log("computing value")
        console.log(computed)
        var computedValue = computed.value.includes('{')? getMergedExpression(computed.value) : computed.value
        
        console.log('computedValue', computedValue)
        try {
            if(typeof(computedValue) == typeof("") && computedValue.includes('(')) {
                console.log('computedValue evaluated', eval(computedValue))
                return eval(computedValue);
            } else {
                return computedValue
            }
        } catch(ex) {
            console.error(ex)
            return `(Missing value(s) for ${variableName})` 
        }
    }

    function setFlowVariable(variableName : string, newValue : any) {
        if(newValue == '\'null\'') newValue = null
        let variable = flowVariables.value.find((item) => item.name === variableName);
        if (variable == null || variable == undefined)
        {
            flowVariables.value.push({ name: variableName, value : newValue });
            lastChange.value = {name: variableName, time: Date.now()}
            return true;
        }
        variable.value = newValue;
        
        lastChange.value = {name: variableName, time: Date.now()}
        return true;
    }

    function getAllFlowVariableNames() {
        const varNames : string[] = new Array();
        for (let i in flowVariables.value) {
            varNames.push(flowVariables.value[i].name)
        }

        return varNames;
    }

    function getMergedExpression(expression: string) {
        if(expression == null || expression.length == 0) return ""

        if(typeof(expression) != 'string') return expression 
        if(expression.length > 5)
            console.log("expression is: ", expression)
        
        // merge in computed values
        expression?.match(/(?<=\{\{variable\.)(.*?)(?=\}\})/gm)?.forEach((m) => {
            var val = getFlowVariable(m, true);
            console.log("repvalue", val)
            if(val != null)
                expression = expression.replaceAll("{{variable." + m + "}}", val);
            else
                expression = expression.replaceAll("{{variable." + m + "}}", null);
        });
      
        expression?.match(/(?<=\{\{computed\.)(.*?)(?=\}\})/gm)?.forEach((m) => {
            console.log("computed variable: ", m)
            expression = expression.replaceAll("{{computed." + m + "}}", getComputedVariable(m));
        });

        if(expression.length > 5)
            console.log("expression is: ", expression)
        try {
            if(expression.length > 5)
                console.log("evaluated it is: ", eval(expression))
            return eval(expression)
        } catch {
            return expression
        }
    }

    function RunActions(context: any, actions: any) {
        actions.forEach((action: any) => {
            if(eval(getMergedExpression(action.condition)))
            {
                action.trueActions.forEach((trueAction: any) => {
                    console.log("action condition true")
                    runAction(context, trueAction)
                })
            }
            else {
                action.falseActions.forEach((falseAction: any) => {
                    console.log("action condition false")
                    runAction(context, falseAction)
                })
            }
        })
    }

    function runAction(context: any, action: any) {
        console.log(action)
        switch(action.type) {
          case "alert":
            ConfirmModal(context, action.title, action.body);
            break;
          case "state":
          case "onClick":
          default: 
            eval(getMergedExpression(action.action))
        }
    }

    function resetStore() {
        
        flowStartDate.value = new Date();
        flowId.value = "";
        flowRunId.value = "";
        flowVariables.value = [];
        computedVariables.value = [];
        // variables.value = flowData.state
        
        lastChange.value = {name: '', time: 0};
    }

    function saveFlow() {
        try {
            flowEndDate.value = new Date();

            const designStore = useScriptDesignStore();
            const authStore = useAuthenticationStore();
            
            const command = new CreateConversationReportCommand();
            command.tenantId = authStore.getTenantId;
            command.agentId = authStore.getUserId;
            command.agentName = authStore.user?.displayName;
            command.campaignId = designStore.campaignId;
            command.campaignName = designStore.campaignName;
            command.conversationId = designStore.scriptId;
            command.conversationName = designStore.scriptName;
            command.startDateTime = flowStartDate.value.toISOString();
            command.endDateTime = flowEndDate.value.toISOString();
            command.abandoned = isEndFlowTriggered.value ? "Y" : "N"; 

  
            command.Execute().then((result) => {
                if (!result.success) {
                    console.log(result.error)
                };

                router.push({ name: 'ViewConversations' });
            })
            
        } catch (e) {
            console.error(e.message);
        }
    }

    return {flowRunId, flowVariables, computedVariables, lastChange, flowId, saveFlow, startNewRun, resetStore, runAction, RunActions, getFlowVariable, setFlowVariable, getAllFlowVariableNames, getMergedExpression, setFlowAbandonedTrigger}
})