// Vuetify
import 'vuetify/styles'
import { createVuetify, type ThemeDefinition } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

import { 
  mdiAccount,
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiAccountPlus,
  mdiAccountOutline,
  mdiAlert,
  mdiApplicationVariableOutline,
  mdiArchive,
  mdiArrowLeftDropCircle,
  mdiArrowLeft,
  mdiArrowLeftDropCircleOutline,
  mdiArrowLeftThin,
  mdiArrowRightDropCircleOutline,
  mdiArrowRightThin, 
  mdiBackspace,
  mdiCheckCircle,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiContentSaveOutline,
  mdiDelete,
  mdiDotsVertical,
  mdiEmail,
  mdiEye,
  mdiEyeOff,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFilterVariant,
  mdiFolderAccount,
  mdiFolderOpen, 
  mdiFormatListBulleted,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiForum,
  mdiHome,
  mdiHomeVariant,
  mdiInformationSlabBox,
  mdiInformationVariantCircle,
  mdiLightbulbOn,
  mdiLogin,
  mdiLogout,
  mdiMagnify,
  mdiMapMarker,
  mdiMenuUp,
  mdiMenuDown,
  mdiMessageQuestion,
  mdiMessageText,
  mdiNoteTextOutline,
  mdiOpenInNew,
  mdiPaletteSwatchOutline,
  mdiPencil,
  mdiPencilRuler,
  mdiPlayCircle,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiPublish,
  mdiRun,
  mdiSitemap,
  mdiStar,
  mdiStarOutline,
  mdiTextAccount,
  mdiTextBox,
  mdiTooltipQuestion,
  mdiTrashCanOutline,
  mdiTrashCan,
  mdiTwoFactorAuthentication,
  mdiViewDashboard,
  mdiWindowClose,
  mdiClose,
  mdiCog,
  mdiContentCopy,
  mdiDatabaseCog,
  
 } from '@mdi/js'

import customSmileyExcellent from '@/components/icons/IconSmileyExcellent.vue'
import customSmileyNeutral from '@/components/icons/IconSmileyNeutral.vue'
import customSmileyPoor from '@/components/icons/IconSmileyPoor.vue'
import customSmileyVeryBad from '@/components/icons/IconSmileyVeryBad.vue'
import customSmileyVeryGood from '@/components/icons/IconSmileyVeryGood.vue'

// Import custom scss file
import '@/scss/app.scss'

//Themes
const defaultTheme: ThemeDefinition = {
  dark: false,
  colors: {
    white: '#ffffff',
    'off-white': '#b6bed2', 
    'bkg-dark': '#0b214e',
    primary: '#3979ba',
    'blue-accent': '#00c2f0',
    secondary: '#174573',
    'accent': '#F19322',
    'primary-darken': '#285582',

    // branding
    'logo-text': '#0A142A',
    'logo-icon': '#F19322', 
    
    // App
    body: '#e6e8ef',
    'page-container': '#ffffff',
    'menu-base': '#112536',
    'menu-state': '#3979ba',
    'menu-hover': '#b366a8',

    // Text
    'text-color': '#0A142A',
    'text-link': '#2e6195',
    'text-link-hover': '#224970',
    'text-header': '#3979ba',

    // borders
    'border-color': '#d2dbdb',

    // Tables
    'row-odd': '#eff4f9',
    'th-header-border': '#07012c',
    
    // Inputs
    'input-border-colour': '#d2dbdb',
    'input-border-hover': '#6194c8',
    'input-border-active': '#285582',
    'input-bkg-colour': '#ffffff',

    'list-bkg': '#ffffff',

    // Buttons
    'primary-button-color': '#3979ba',
    'primary-button-color-hover':'#285582',
    'primary-button-prepend-color': '#336da7',
    'primary-button-prepend-border-color': '#285482',
    
    'secondary-button-color-hover':'#12375C',
    'secondary-button-prepend-color': '#103051',
    'secondary-button-prepend-border-color': '#0E2945',

    'text-variant-color': '#354F86',
    'text-variant-color-hover': '#275384',

    'ghost-button-border': '#88afd6',
    'ghost-button-background-hover': '#D7E4F1',

    'positive-button-color': '#7ea236',
    'positive-button-hover': '#5e7a33',
    'positive-button-border': '#5e7a33',


    'add-row-border': '#88afd6',
    'add-row-background-hover' : '#D7E4F1',

    // Tooltips
    'tooltip-default': '#112437',

    // Dialogs
    'dialog-body-background': '#ffffff',
    'dialog-btn-row-background': '#f9f9f9',
    'timeout-svg': '#285582', 

    // Cards
    'card-background-offset': '#f6f7f7',
    'card-background-inset': '#ffffff',

    // Filter
    'filter-bkg': '#ffffff',
    'filter-actions-bkg': '#f9f9f9',

    // Error styles
    error: '#b8312a',
    'error-bkg': '#cf323b',
    'input-border-error': '#b8312a',
    'input-border-error-hover': '#81221d',

    //Smileys
    'smiley-very-bad': '#bd1e2c',
    'smiley-poor': '#df5166',
    'smiley-neutral': '#faaf40',
    'smiley-very-good': '#8dc53e',
    'smiley-excellent': '#006838',

    // Theme switcher
    'tswitch-border': '#d2dbdb',
    'tswitch-bkg': '288de0',
    'tswitch-thumb': '#ee902f',

    //Guided convesation prompts
    'prompt-speech': '#00af54',
    'prompt-speech-bkg': '#edf4f1',
    'prompt-instruction': '#336da7',
    'prompt-instruction-bkg': '#f8fbff',
    'prompt-info': '#f7b131',
    'prompt-info-bkg': '#f8f5f0',
    
    //Conversation 
    'conversation-nav-bkg': '#f6f7f7',
    'conversation-nav-border': '#3979ba',
    'conversation-nav-border-top': '#3979ba', 
    'conversation-nav-icon-border': '#d2dbdb',

    //End Flow
    'end-flow-bkg': '#ebf3da',
    'end-flow-border': '#c4dd8f',
    'end-flow-icon': '#5e7a33',

    // form-builder
    'number-wrap-text': '#ffffff',
    'number-wrap-bkg': '#112536',
    'info-color': '#A2B5C7',
    'info-hover-color': '#3979ba',
    'card-switcher-bkg': '#eaeff4',
    'sort-item-bkg': '#eaeff4',
    'sort-item-border': '9cbcdd',

    // fields
    'field-bkg': '#ffffff',

    // Side panel
    'side-panel-bkg': '#ffffff',

    // These need sanitised into better values
    'primary-darken-1': '#22694f',
    'primary-darken-2': '194f3b',
    'start-node-background': '#9DCA46',
    'form-node-background': '#F6F9FC',
    'generic-node-background': '#F6F9FC',
    'result-node-background': '#ff9192',
    'result-node-background-secondary': '#9DCA46',
    'linked-node-background': '#5D9CE1',
    'node-text-color': '#0A142A',
    'node-connector-color': '#000000',
    'input-node-background-color': '#B0C9E5',

    // Chips
    'chip-backgound': '#B0C9E5',
    'red': '#cf323b',
  },
}

const defaultThemeDark: ThemeDefinition = {
  dark: true,
  colors: {
    white: '#ffffff',
    'off-white': '#b6bed2', 
    'bkg-dark': '#0b214e',
    primary: '#3979ba',
    'blue-accent': '#00c2f0',
    secondary: '#281e61',
    'accent': '#F19322',
    
    // branding
    'logo-text': '#0A142A',
    'logo-icon': '#F19322', 
    
    // App
    body: '#14152f',
    'page-container': '#0e0f2d',
    'menu-base': '#0e0f2d',
    'menu-state': '#F19322',
    'menu-hover': '#3979BA',

    // Text
    'text-color': '#ffffff',
    'text-link': '#8cafd4',
    'text-link-hover': '#b2c9e2',
    'text-header': '#6194C8',

    // borders
    'border-color': '#444874',

    // Tables
    'row-odd': '#0b0c24',
    'th-header-border': '#ffffff',
    
    // Inputs
    'input-border-colour': '#2f557e',
    'input-border-hover': '#6194c8',
    'input-border-active': '#285582',
    'input-bkg-colour': '#040819',

    'list-bkg': '#0b0c24',

    // Buttons
    'primary-button-color': '#3979ba',
    'primary-button-color-hover':'#285582',
    'primary-button-prepend-color': '#336da7',
    'primary-button-prepend-border-color': '#285482',
    
    'secondary-button-color-hover':'#f59733',
    'secondary-button-prepend-color': '#20184e',
    'secondary-button-prepend-border-color': '#1c1544',

    'text-variant-color': '#354F86',
    'text-variant-color-hover': '#275384',

    'positive-button-color': '#7ea236',
    'positive-button-hover': '#5e7a33',
    'positive-button-border': '#5e7a33',

    // 'ghost-button-border': '#88afd6',
    // 'ghost-button-background-hover': '#D7E4F1',


    'add-row-border': '#abc0d5',

    // Tooltips
    'tooltip-default': '#F19322',

    // Dialogs
    'dialog-body-background': '#14152f',
    'dialog-btn-row-background': '#0e0f2d',
    'timeout-svg': '#ffffff',

    // Cards
    'card-background-offset': '#0e0f2c',
    'card-background-inset': '#14152e',

    // Filter
    'filter-bkg': '#14152f',
    'filter-actions-bkg': '#0e0f2d',

    // Error styles
    error: '#E48B86',
    'error-bkg': '#cf323b',
    'error-bkg-border': '#a6282f',
    'input-border-error': '#E48B86',
    'input-border-error-hover': '#ECAEAA',

    //Smileys
    'smiley-very-bad': '#bd1e2c',
    'smiley-poor': '#df5166',
    'smiley-neutral': '#faaf40',
    'smiley-very-good': '#8dc53e',
    'smiley-excellent': '#006838',

    // Theme switcher
    'tswitch-border': '#234674',
    'tswitch-bkg': '#080925',
    'tswitch-thumb': '#b9deed',

    //Guided convesation prompts
    'prompt-speech': '#00af54',
    'prompt-speech-bkg': '#14152e',
    'prompt-instruction': '#336da7',
    'prompt-instruction-bkg': '#14152e',
    'prompt-info': '#f7b131',
    'prompt-info-bkg': '#14152e',

    //Conversation 
    'conversation-nav-bkg': '#14152e',
    'conversation-nav-border': '#3979ba',
    'conversation-nav-border-top': '#202451',   
    'conversation-nav-icon-border': '#d2dbdb',

    
    //End Flow
    'end-flow-bkg': '#1f2812',
    'end-flow-border': '#c4dd8f',
    'end-flow-icon': '#c4dd8f',


    // form-builder
    'number-wrap-text': '#ffffff',
    'number-wrap-bkg': '#112536',
    'info-color': '#A2B5C7',
    'info-hover-color': '#3979ba',
    'card-switcher-bkg': '#0B0C23',
    'sort-item-bkg': '#2e6195',
    'sort-item-border': '6194c8',
    // 'card-background-offset': '#0e0f2c',
    // 'card-background-inset': '#14152e',

    // fields
    'field-bkg': '#0e0f2c',


    // Side panel
    'side-panel-bkg': '#14152e',

    // These need sanitised into better values
    'primary-darken-1': '#22694f',
    'primary-darken-2': '194f3b',
    'start-node-background': '#9DCA46',
    'form-node-background': '#5d9ce1',
    'result-node-background': '#ff9192',
    'result-node-background-secondary': '#9DCA46',
    'generic-node-background': '#F6F9FC',
    'linked-node-background': '#5D9CE1',
    'node-button-background': '#000000',
    'node-text-color': '#0A142A',
    'node-connector-color': '#ffffff',
    'input-node-background-color': '#B0C9E5',

    // Chips
    'chip-text': '#112437',
    'chip-backgound': '#F19322',

    // Typeahead
    'clear-icon': '#F19322',
    'red': '#cf323b',
    'input-text':'#ffffff',
  },
}
  

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'defaultTheme',
    themes: {
      defaultTheme,
      defaultThemeDark,
    },
  },
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    
    aliases: {
      ...aliases,
      account: mdiAccount,
      accountGroup: mdiAccountGroup,
      accountMultiple: mdiAccountMultiple,
      accountPlus: mdiAccountPlus,
      accountOutline: mdiAccountOutline,
      alert: mdiAlert,
      applicationVariableOutline: mdiApplicationVariableOutline,
      archive: mdiArchive,
      arrowLeftThin: mdiArrowLeftThin,
      arrowLeft: mdiArrowLeft,
      arrowLeftCircleOutline: mdiArrowLeftDropCircleOutline,
      arrowRightThin: mdiArrowRightThin,
      arrowRightCircleOutline: mdiArrowRightDropCircleOutline,
      backspace: mdiBackspace,
      checkCircle :mdiCheckCircle,
      chevronDown: mdiChevronDown,
      chevronLeft: mdiChevronLeft,
      chevronRight: mdiChevronRight,
      chevronUp: mdiChevronUp,    
      closeCircleOutline: mdiCloseCircleOutline,
      dashboard: mdiViewDashboard,
      delete: mdiDelete,
      contentSaveOutline: mdiContentSaveOutline,
      dotsVertical: mdiDotsVertical,
      email: mdiEmail,
      eye: mdiEye,
      eyeNotVisible: mdiEyeOff,
      eyeOff: mdiEyeOffOutline,
      eyeOn: mdiEyeOutline,
      filter: mdiFilterVariant,
      folderAccount: mdiFolderAccount,
      folderOpen: mdiFolderOpen,
      formatList: mdiFormatListBulleted,
      formatBold: mdiFormatBold,
      formatItalic: mdiFormatItalic,
      formatUnderline: mdiFormatUnderline,
      forum: mdiForum,
      home: mdiHome,
      homeVariant: mdiHomeVariant,
      info: mdiInformationSlabBox,
      infoCircle: mdiInformationVariantCircle,
      lightbulbOn: mdiLightbulbOn,
      login: mdiLogin,
      logout: mdiLogout,
      magnify: mdiMagnify,
      mapMarker: mdiMapMarker,
      menuUp: mdiMenuUp,
      menuDown: mdiMenuDown,
      messageQuestion: mdiMessageQuestion,
      messageText: mdiMessageText,
      noteTextOutline: mdiNoteTextOutline,
      openInNew: mdiOpenInNew,
      paletteSwatchOutline: mdiPaletteSwatchOutline,
      pencil: mdiPencil,
      publish: mdiPublish,
      pencilRuler: mdiPencilRuler,
      playCircle: mdiPlayCircle,
      plus: mdiPlus,
      plusCircleOutline: mdiPlusCircleOutline,
      run: mdiRun,
      siteMap: mdiSitemap,
      star: mdiStar,
      starOutline: mdiStarOutline,
      textAccount: mdiTextAccount,
      textBox: mdiTextBox,
      tooltipQuestion: mdiTooltipQuestion,
      trashCanOutline: mdiTrashCanOutline,
      trashCan: mdiTrashCan,
      twoFactorAuthentication: mdiTwoFactorAuthentication,
      windowClose: mdiWindowClose,
      close: mdiClose,
      cog: mdiCog,
      dbCog: mdiDatabaseCog,
      copy: mdiContentCopy ,
      
      // Custom icons
      smileyExcellent: customSmileyExcellent,
      smileyNeutral: customSmileyNeutral,
      smileyPoor: customSmileyPoor,
      smileyVeryBad: customSmileyVeryBad,
      smileyVeryGood: customSmileyVeryGood,
    },
    sets: {
      mdi,
    },
    
  },
})

export default vuetify;