// Utilities
import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { iScripts } from './api/designInterfaces'
import type { iEntryElement } from './api/designInterfaces'
import GetScriptByScriptIdQuery from '@/common/api/queries/Script/GetScriptByScriptId'
import GetFormByFormIdQuery from '@/common/api/queries/Form/GetFormByFormId'
import TestFlowCommand from '@/common/api/commands/Flows/TestFlowCommand'
import { ConfirmErrorModal, ConfirmErrorModalTitle } from '@/common/alerts'

export const useScriptDesignStore = defineStore('scriptdesign', () => {

  const campaignId = ref('')
  const campaignName = ref('')
  const scriptId = ref('')
  const scriptName = ref('')
  const scriptFlow = ref<iScripts>()
  const entryElements = ref<iEntryElement[]>([])
  const isLoaded = ref(false);
  
  function testFlow(thiz: any, campaignId: string, flowId: string, callback: any, failureCallback: any) {
    new TestFlowCommand(campaignId, flowId).Execute().then((resp) => {
        if(resp.status == 200) {
          resp.json().then((value) => {
            console.log(value)
        
            scriptName.value = value.scriptName
            scriptId.value = "TestFlow"
            scriptFlow.value = value.script;
            entryElements.value = value.forms
            isLoaded.value = true;
    
            callback()
          })
        }if(resp.status == 500) {
            ConfirmErrorModal(thiz, "Test Flow failed for an unknown reason. contact support.", failureCallback())   
        } else {
          resp.json().then((err: any) => {
              var error = ""
              for (const section in err.errors) {
                  error += `<strong style="text-align: left; display: block;font-weight:normal; margin-bottom: 5px">${section}:</strong>`;
                  error += `<ul style="list-style-type: disc; margin-left: 20px;">`;
                  for (const errorLine of err.errors[section]) {
                      error += `<li style="text-align: left;">${errorLine}</li>`;
                  }
                  error += `</ul><br>`;
              }
              ConfirmErrorModalTitle(thiz, "Cannot publish flow", error, failureCallback())
          })
        }
    })
  }

  function loadScriptDesign(apiKey: string, loadScriptId: string, callback: any) {
    if(loadScriptId == scriptId.value) {
      callback()
    } else {
      isLoaded.value = false;
      try {
        new GetScriptByScriptIdQuery(loadScriptId)
          .Execute()
          .then((response: any) => response.json()).then((results:any) => {
            scriptName.value = results.scriptName
            scriptId.value = loadScriptId
            scriptFlow.value = results;
            isLoaded.value = true;
            callback()
        })
      } catch (error) {
        return error
      }
    }
  }

  function reset() {
    
    campaignId.value = ''
    campaignName.value = ''
    scriptId.value = ''
    scriptName.value = ''
    scriptFlow.value = undefined
    entryElements.value = []
    isLoaded.value = false;
  }

  function getStartPoint() {
    if (scriptFlow.value == null || scriptFlow.value == undefined)
      return "";

    return scriptFlow.value['start'];
  }

  function getScriptItem(itemId: string) {
    // console.log("getScriptItem")
    // console.log(scriptFlow.value)
    if (scriptFlow.value == null || scriptFlow.value == undefined)
    {
      return null;
    }

    if (itemId == null || itemId == undefined || itemId === "")
    {
      return null;
    }

    var item = scriptFlow.value["items"].find(i => i.id == itemId);
    // console.log('item')
    // console.log(item)
    return item
  }

  async function getElementDesign(elementId: string) {
    // console.log("getElementDesign")
    // console.log(elementId)

    if (entryElements.value == null || entryElements.value == undefined)
    {
      entryElements.value = []
    }

    if (elementId == null || elementId == undefined || elementId === "")
      return null;

    let formElements = entryElements.value.find(e => e.id == elementId);

    if(formElements == null)
    {
      return new GetFormByFormIdQuery(elementId)
      .Execute()
      .then((response: any) => response.json())
      .then((results:any) => {
        entryElements.value.push(results)
        formElements = results
        return formElements
      })
    } else {
      console.log(formElements)
      return formElements
    }

  }

  function setCampaignId(campaignIdentifier: string){
    campaignId.value = campaignIdentifier; 
  }

  function setCampaignName(name: string){
    campaignName.value = name;
  }

  return { campaignId, campaignName, scriptId, scriptName, scriptFlow, isLoaded, entryElements, reset, testFlow, setCampaignId, setCampaignName, loadScriptDesign, getStartPoint, getScriptItem, getElementDesign }
})
