<template>
    <div>
        <div 
            class="side-panel-underlay"
            :class="open ? 'side-panel--open' : 'side-panel--closed'">
        </div>
        <aside
            id="side-panel"
            class="side-panel help-panel"
            :class="open ? 'side-panel--open' : 'side-panel--closed'"
            tabindex="-1"
            >
            
            <div class="help-panel__viewport">

                <header class="help-panel-header">
                    <h2 class="help-panel-header__section-title">
                        Help
                    </h2>
                
                    <div class="help-panel-header__btn-close">
                        <v-btn
                            @click="$emit('close')"
                            id="side-panel-close"
                            type="submit"
                            density="compact"             
                            icon
                            class="i-btn i-btn--filter-close" 
                            ref="sidePanelClose"
                            ><span class="sr-only">{{ $t('common.close') }}</span>
                            <v-icon icon="$closeCircleOutline"></v-icon>
                        </v-btn>    
                    </div>
        
                </header>
                <body>
                    <section class="help-content-wrappper">
                        <div class="help-navigation">
                            <h2 style="margin-left: 1em;">Contents:</h2>
                            <ul>
                                <li v-for="page in helpDocs">
                                    <a 
                                        v-if="page.children == undefined || page.children == null || page.children.length == 0" 
                                        :class="{active: currentPage.id == page.id}" 
                                        @click="setPage(page.id)" >
                                            {{page.title}}
                                    </a>
                                    <template v-else>
                                        <a 
                                            :class="{active: currentPage.id == page.id}"
                                             >
                                            <div @click="handleMenuItemClick(page.id)"
                                                style="width: calc(100% - 2em); display: inline-block">
                                                {{page.title}}
                                            </div>
                                            <span
                                                role="button"
                                                style="width: 2em; display: inline-block"
                                                @click="handleMenuItemClick(page.id)">
                                                <v-icon :icon="expanded.includes(page.id)? '$chevronUp' : '$chevronDown'"></v-icon>
                                            </span>    
                                        </a>
                                        <li v-if="expanded.includes(page.id)" v-for="child in page.children">
                                            <a 
                                                :class="{active: currentPage.id == child.id}" 
                                                @click="setPage(child.id)" >
                                                    {{child.title}}
                                            </a>
                                        </li> 
                                    </template>
                                </li>
                            </ul>
                        </div>
                        <article 
                            v-html="currentPage.html"
                            class="help-content-wrap"
                            ref="helpContainer"
                            >
                    	</article>
                    </section>
                </body>
            </div>

        </aside>  
    </div>
</template>

<script lang="ts">
import pages from '@/assets/help'

export default {
    props: [
        "open"
    ],
    computed: {
        currentPage() {
			return this.flattenRoutes(this.helpDocs).filter(c => c.id == this.activePage)[0]
        }
    },
    updated() {
        var elements = (this.$refs["helpContainer"] as Document).getElementsByTagName("a") as HTMLCollectionOf<HTMLAnchorElement>

		if(elements.length > 0)
        {
            for (let index = 0; index < elements.length; index++) {
                const element = elements[index] as HTMLAnchorElement;
                const href = element.href
				if (!href) continue;

				const url = new URL(href)
                const helpPageNameRegex = /([a-zA-Z0-9_-]+)\.htm$/;
                const returnVal = url.pathname.match(helpPageNameRegex)

				if(returnVal != null)
                {
					const hash = url.hash;
                    const helpPageName = returnVal[1];
                    element.href = "javascript: void(0)"
                    element.addEventListener("click", () => {
						this.setPage(helpPageName)
						
						this.$nextTick(() => {
							if (hash) {
								this.navigateToHash(hash.slice(1));
							}
						})
					})
                }
            }
        }
    },
    methods: {
        flattenRoutes(routes: any[]){
            var a = [] as any[];
            for(var i=0; i < routes.length; i++){
                if(routes[i].children){
                    a = a.concat(this.flattenRoutes(routes[i].children) )
                }
                a.push(routes[i])
            }
            return a;
        },
        toggleExpanded(pageId: string) {
            var index = this.expanded.indexOf(pageId)
            if(index != -1) {
                this.expanded.splice(index, 1)
            } else {
                this.expanded.push(pageId)
            }
        },
        setPage(name: string) {
            this.activePage = name
        },
		handleMenuItemClick(name: string){
			this.setPage(name);
			this.toggleExpanded(name);
		},
		navigateToHash(hash: string) {
			var anchorElement = (this.$refs["helpContainer"] as Document).querySelector(`a[name='${hash}']`);
			
			if (!anchorElement) {
				anchorElement = (this.$refs["helpContainer"] as Document).querySelector(`#${hash}`);
			}

			if (anchorElement) {
				anchorElement.scrollIntoView({ behavior: 'smooth' });
			}
		}
    },
    data() {
        return {
            helpDocs: pages,
            expanded: [] as string[],
            activePage: pages[0].id
        }
    }
    
}
</script>

<style scoped>

:root {
    --primary-color: #3979ba;
    --gutter: 16px;
    --border-color: #d2dbdb;
    --spacer: 20px;
    --nav-active: #36454F;
    --nav-hover: #285582;
}

body,
html {
    margin: 0;
    padding: 0;
}  
body {
    font-family: roboto;
    padding-left: var(--gutter);
    padding-right: var(--gutter);
    font-size: 16px;
}
.help-navigation {
    padding-top: var(--gutter);
    padding-bottom: var(--gutter);
    border-bottom: 1px solid var(--v-theme-border-color);
}
.help-navigation h2 {
    font-size: 18px;
}
.help-navigation ul,
.help-navigation li {
    list-style: disc;
    margin: 0;
    padding: 0;
}
.help-navigation li {
    list-style-type: none;
    margin-left: 20px;
    margin-bottom: 8px;
}
.help-navigation li a {
    text-decoration: none;
    color: var(--primary-color);
    transition: all .2s ease; 
}
.help-navigation li a.active {
    text-decoration: none;
    color: var(--nav-active);
}
.help-navigation li a:hover {
    text-decoration: underline;
    color: var(--nav-hover);
    cursor: pointer;
}
.help-navigation li a.active:hover {
    text-decoration: none;
}

#mc-main-content > h1 {
    font-size: 22px;
    padding-top: 16px;
}
#mc-main-content > h2 {
    padding-top: 16px;
    font-size: 20px;
}
#mc-main-content p {
    line-height: 1.4em;
}
#mc-main-content a {
    color: var(--primary-color);
}
#mc-main-content a:hover {
    text-decoration: none;
}

@media only screen and (min-width: 760px) {
    body {
        padding-left: 20px;
        /* padding-right: 30px; */
    }
    .help-navigation {
        width: 220px;
        border-bottom: none;
        border-right: 1px solid rgb(var(--v-theme-border-color));
        padding-top: 25px;
        padding-bottom: 0px;
        flex-shrink: 0;
    }
    .help-navigation h2 {
        margin-bottom: 12px;
    }
    #mc-main-content {
        padding-left: 20px;
        padding-top: 20px;
    }
    .help-content-wrappper {
        display: flex;
        /* min-height: 100vh; */
    }
    .help-content-wrap {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 20px;
		padding-bottom: 20px;

    }
	.help-content-wrap dt {
    	font-weight: bold;
	}

    ::-webkit-scrollbar {
        width: 7px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 0px;
        background: #f0f0f0;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.08);
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background: #a6aeae;
        transition: all .4s ease;       
    }
    ::-webkit-scrollbar-thumb:horizontal:hover,
    ::-webkit-scrollbar-thumb:vertical:hover { 
        background: #7d8282;
    }
}

/*<meta />*/

@namespace MadCap url(http://www.madcapsoftware.com/Schemas/MadCap.xsd);

body
{
	color: #2e475d;
	font-family: Roboto;
	padding: 2% 2%;
	background-color: #FFFFFF;
}

/* Backmatter */

div.GlossaryPageDefinition
{
	font-size: 10pt;
}

div.GlossaryPageHeading
{
	font-size: 21pt;
	font-weight: normal;
	margin-bottom: 15px;
	margin-top: 15px;
	border-bottom-color: #dbe4ed;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	text-align: left;
	color: inherit;
	padding-left: 10px;
	padding-bottom: 20px;
	padding-top: 20px;
	padding-right: 10px;
	background-image: url('../images/backmatter-heading-background.png');
	background-repeat: no-repeat;
}

div.GlossaryPageTerm
{
	font-size: 12pt;
	font-weight: bold;
	padding-bottom: 10px;
	padding-top: 10px;
}

MadCap|glossaryProxy
{
	border-top: none;
}

MadCap|indexProxy
{
	border-top: none;
}

p.Index1
{
	font-size: 10pt;
	font-weight: bold;
}

p.Index2
{
	font-size: 10pt;
	font-weight: normal;
}

p.IndexHeading
{
	font-size: 21pt;
	font-weight: normal;
	margin-bottom: 15px;
	margin-top: 15px;
	border-bottom-color: #dbe4ed;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	text-align: left;
	color: inherit;
	padding-left: 10px;
	padding-bottom: 20px;
	padding-top: 12px;
	padding-right: 10px;
	background-image: url('../images/backmatter-heading-background.png');
	background-repeat: no-repeat;
}

/* Content */

img
{
	border: none;
	max-width: 100%;
}

p > img
{
	text-align: center;
}

p
{
	font-size: 12.0pt;
	margin-top: 20px;
	margin-bottom: 20px;
	line-height: 20pt;
	letter-spacing: 0.25px;
	widows: 3;
	orphans: 3;
}

p.table-text
{
	font-size: 12pt;
}


/* styles for conditioned content */

[data-mc-conditions*="Default.Draft"]
{
	background-color: #d86abd;
}

/* Frontmatter */

hr.title-page
{
	border: solid 0px transparent;
	background-color: #f6f9fc;
	height: 1px;
	width: 100%;
	margin-bottom: 30px;
	margin-top: 370px;
}

p.title
{
	font-size: 55pt;
	line-height: 65pt;
	margin-top: 30px;
	mc-hyphenate: never;
}

p.title-page-url
{
	color: #2e475d;
	font-size: 9pt;
	line-height: 15pt;
}

/* Headings */

h1
{
	font-weight: bold;
	font-size: 24.0pt;
	color: #1b2e58;
}

h2
{
	font-weight: bold;
	font-size: 18.0pt;
	page-break-before: always;
}

h3
{
	font-weight: bold;
	font-size: 14.0pt;
}

h4
{
	font-weight: bold;
	font-size: 12.0pt;
}

h5
{
	font-weight: bold;
	font-size: 10.0pt;
}

h6
{
	font-weight: bold;
	font-size: 8.0pt;
}

/* Home page topic */

div.home-section
{
	padding: 3% 2%;
	border-bottom: solid 1px #f6f9fc;
}

div.home-section:first-of-type	/*Removes padding-top of the first home-section*/
{
	padding: 0 2% 3%;
}

div.home-section:last-of-type	/*Removes bottom border of last home-section*/
{
	border-bottom: none;
}

div.quick-links	/*This is a custom responsive layout row style (div class) in your stylesheet. To provide your own description for this style: (1) Open the stylesheet; (2) Find and select the div class with this name; and (3) In the Comment field, replace this text with your own. For more information on adding comments to styles, see the online Help.*/
{
	mc-grid-row: true;
	margin-left: auto;
	margin-right: auto;
}

div.quick-links::before
{
	content: ' ';
	display: table;
}

div.quick-links::after
{
	content: ' ';
	display: table;
	clear: both;
}

div.quick-links > div
{
	float: left;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

div.quick-links > div:nth-child(1)
{
	width: 16.66666%;
	margin-left: 2%;
}

div.quick-links > div:nth-child(2)
{
	width: 16.66666%;
	margin-left: 3%;
}

div.quick-links > div:nth-child(3)
{
	width: 16.66666%;
	margin-left: 3%;
}

div.quick-links > div:nth-child(4)
{
	width: 16.66666%;
	margin-left: 3%;
}

div.quick-links > div:nth-child(5)
{
	width: 16.66666%;
	margin-left: 3%;
}

div.quick-links *	/*Text-align center set to all elements within div.quick-links*/
{
	text-align: center;
}

/* Master page */

.home-footer-logo
{
	background-image: url('../Images/primary-logo-inverted.png');
	background-repeat: no-repeat;
	background-position: right center;
	width: 400px;
}

MadCap|breadcrumbsProxy
{
	border-bottom-width: 0;
	color: #2e475d;
}

@media only screen and (max-width: 1279px)
{
	/* Home page topic */

	div.quick-links > div:nth-child(1)
	{
		width: 24%;
		margin-left: 0%;
	}

	div.quick-links > div:nth-child(2)
	{
		width: 24%;
		margin-left: 14%;
	}

	div.quick-links > div:nth-child(3)
	{
		width: 24%;
		margin-left: 14%;
	}

	div.quick-links > div:nth-child(4)
	{
		width: 24%;
		margin-left: 19%;
	}

	div.quick-links > div:nth-child(5)
	{
		width: 24%;
		margin-left: 14%;
	}
}

@media only screen and (max-width: 767px)
{
	/* Home page topic */

	div.quick-links > div:nth-child(1)
	{
		width: 49%;
		margin-left: 0;
	}

	div.quick-links > div:nth-child(2)
	{
		width: 49%;
		margin-left: 2%;
	}

	div.quick-links > div:nth-child(3)
	{
		width: 49%;
		margin-left: 0;
	}

	div.quick-links > div:nth-child(4)
	{
		width: 49%;
		margin-left: 2%;
	}

	div.quick-links > div:nth-child(5)
	{
		width: 49%;
		margin-left: 25.5%;
	}

	.home-footer-logo
	{
		padding: 20px;
		background-origin: content-box;
		background-size: contain;
	}
}

@media print
{
	body
	{
		font-size: 11pt;
		line-height: 13pt;
		margin: 8px;
		background-color: transparent;
		padding: 0%;
	}

	/* Content */

	p
	{
		margin-bottom: 10px;
		margin-top: 10px;
	}

	p.table-text
	{
		font-size: 11pt;
	}

	/* Headings */

	h1
	{
		font-size: 25pt;
		line-height: 27pt;
		margin-bottom: 20px;
	}

	h2
	{
		font-size: 21pt;
		font-weight: normal;
		line-height: 23pt;
		margin-bottom: 18px;
		margin-top: 40px;
	}

	h3
	{
		font-size: 15pt;
		font-weight: bold;
		line-height: 17pt;
		margin-bottom: 15px;
		margin-top: 40px;
	}

	/* Links */

	MadCap|xref
	{
		font-weight: normal;
		mc-format: '{quote}{para}{quote} {pageref}';
		text-decoration: none;
	}
}
</style>

<style>
@namespace MadCap url(http://www.madcapsoftware.com/Schemas/MadCap.xsd);

#mc-main-content dd,
#mc-main-content dd p,
#mc-main-content dt,
#mc-main-content dt p,
#mc-main-content li,
#mc-main-content li p
{
	margin-bottom: 8px;
	margin-top: 8px;
}

#mc-main-content dl,
#mc-main-content ol,
#mc-main-content ul
{
	letter-spacing: .25px;
	line-height: 20pt;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-inline-start: 40px;
}

/* styles for tips, notes, and risks */

.TipBox
{
	background-color: #faf4a1;
	color: #000000;
	display: block;
	padding: 10px 20px;
}

.NoteBox
{
	background-color: #7af6f7;
	color: #000000;
	display: block;
	padding: 10px 20px;
}

.ExampleBox
{
	background-color: #dbe4ed;
	color: #000000;
	display: block;
	padding: 10px 20px;
}

.CautionBox
{
	background-color: #f18a85;
	color: #000000;
	display: block;
	padding: 10px 20px;
}

/* styles for signposting boxes */

.SignpostAdvAdm
{
	background-color: #d1d5de;
	padding: 5px 10px;
}

.SignpostAdvArch
{
	background-color: #d1d5de;
	padding: 5px 10px;
}

.SignpostAdvFlow
{
	background-color: #d1d5de;
	padding: 5px 10px;
}

.SignpostAdvArchFlow
{
	background-color: #d1d5de;
	padding: 5px 10px;
}

/* table styles used by the form controls reference tables of properties */

#mc-main-content table
{
	border-spacing: 0;
	margin: 0 0 48px 0;
}

#mc-main-content thead > tr > th,
#mc-main-content tbody > tr > th
{
	background-color: #2e475d;
	color: #f6f9fc;
	text-align: left;
	vertical-align: top;
	padding: 16px;
	margin: 0;
	border: 1px solid #dbe4ed;
}

#mc-main-content thead > tr > td
{
	background-color: #fff;
	border: 0;
}

#mc-main-content thead > tr > th
{
	background-color: #2e475d;
	color: #f6f9fc;
}

#mc-main-content tbody > tr
{
	margin: 0;
	padding: 0;
}

#mc-main-content tbody > tr > td
{
	border: 1px solid #dbe4ed;
	margin: 0;
	padding: 16px;
}

#mc-main-content td.left,
#mc-main-content td > p
{
	text-align: left;
}

#mc-main-content p.table-text
{
	font-size: 12pt;
}

#mc-main-content > h1 {
    font-size: 22px;
    padding-top: 16px;
}
#mc-main-content > h2 {
    padding-top: 16px;
    font-size: 20px;
}
#mc-main-content p {
    line-height: 1.4em;
}

#mc-main-content a:hover {
    text-decoration: none;
}

#mc-main-content img
{
	border: none;
	max-width: 100%;
}

#mc-main-content p > img
{
	text-align: center;
}

#mc-main-content p
{
	font-size: 12.0pt;
	margin-top: 20px;
	margin-bottom: 20px;
	line-height: 20pt;
	letter-spacing: 0.25px;
	widows: 3;
	orphans: 3;
}

#mc-main-content p.table-text
{
	font-size: 12pt;
}

#mc-main-content a,
#mc-main-content a:link,
#mc-main-content a:focus,
#mc-main-content a:hover,
#mc-main-content a:active
{
	font-weight: bold;
}

#mc-main-content a
{
	color: #2E335F;
	text-decoration: underline;
}

#mc-main-content a:link
{
	color: #2E335F;
}

#mc-main-content a:visited
{
	color: #2E335F;
}

#mc-main-content dt {
	font-weight: bold;
}

#mc-main-content dd {
    display: block;
    margin-inline-start: 40px;
    unicode-bidi: isolate;
}

span.mc-variable.firstLetterCap {
    text-transform: capitalize;
}

</style>