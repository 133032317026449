import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import { registerPlugins } from './plugins'

const app = createApp(App)

registerPlugins(app)

Date.prototype.addDays = function(days: any) {
    // this.setDate(this.getDate() + days)
    var ms = this.getTime() + (86400000 * (typeof(days) == 'number'? days : parseInt(days)));
    return new Date(ms);
    // return this
};

Date.prototype.addMonths = function(months: any) {
    // console.log(this)
    // console.log(this.getMonth())
    // console.log(months)
    // console.log(typeof(months))
    this.setMonth(this.getMonth() + (typeof(months) == 'number'? months : parseInt(months)))
    // console.log(this.getMonth())

    return this
};

Date.prototype.addYears = function(years: number) {
    this.setFullYear(this.getFullYear() + (typeof(years) == 'number'? years : parseInt(years)), this.getMonth(), this.getDate())

    return this
};

Date.prototype.toString = function() {
    var intlSettings = Intl.DateTimeFormat().resolvedOptions()
    return this.toLocaleString(intlSettings.locale, {timeZone: intlSettings.timeZone})
} 

Date.prototype.getAge = function() {
    var today = new Date();
    var age = today.getFullYear() - this.getFullYear();
    var m = today.getMonth() - this.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < this.getDate())) {
        age--;
    }
    return age;
};

Date.prototype.equals = function(comparison: Date) {
    return this.getTime() == comparison.getTime()
};

app.mount('#app')
