<template>
    <InisoftContainer screen='logins'>
        <main
            class="logins-wrapper"
            role="main"
        >
            <div class="logins-wrapper__column">
                
                <h1 class="sr-only">ADVISGO</h1>
                
                <mq-responsive group>  
              
                    <template #xs>
                        <product-logo />
                        <flow-pattern-header-right />
                    </template>

                    <template #sm>
                        <product-logo />
                        <flow-pattern-header-right-wide />
                    </template>

                    <template #md+>
                        <product-icon-logo />
                    </template>

                </mq-responsive>

                <section class="login-intro">
                    <h2>
                        {{ $t('loginPage.welcome') }}
                    </h2>
                    <p>
                        {{ $t('loginPage.summary') }}
                        {{ $t('loginPage.summarySub') }}

                    </p>
                </section>
                
                <InisoftForm
                    id="login" 
                    header="Advisgo" 
                    title="login" 
                    titleSummary="loginPage.continueYourJourney" 
                    @submitted="logIn"
                    >

                    <InisoftTextInput 
                        id="username"
                        :label="$t('common.email')"
                        v-model="username" 
                        />

                    <InisoftTextInput 
                        id="password"
                        :label="$t('common.password')"
                        v-model="password"
                        :append-inner-icon="!passwordVisible ? '$eyeOff' : '$eyeOn'"
                        @click:append-inner="passwordVisible = !passwordVisible"
                        :type="passwordVisible? 'text' : 'password'"
                        />

                    <v-sheet class="password-link" 
                        @click="$router.push({name: 'password-reset'})">
                        <a >{{ $t('loginPage.forgotPasswordLink') }}</a>
                    </v-sheet>

                    <v-sheet class="i-field btn-row">
                        <slot name="button-group">
                            <v-btn
                                id="login-submit" 
                                type="submit"
                                color="primary"
                                class="i-btn i-btn--primary"
                                size="large"
                            >{{ $t("common.logIn") }}</v-btn>
                        </slot>
                    </v-sheet>
                
                </InisoftForm>

                <section class="login-footer">
                    <small>{{ $t("common.softwareBy") }} <a href="https://www.inisoft.com/" target="_blank">Inisoft</a></small>
                </section>

            </div>

            <div class="logins-wrapper__brand-block">              
                 <mq-responsive group>  
                    <template #md+>
                        <flow-pattern-BB-top-left />
                        <flow-pattern-BB-top-right />
                        <flow-pattern-BB-bottom-right />
                        <flow-pattern-BB-bottom-left />  
                    </template>
                </mq-responsive>
                <product-logo />
            </div>
            
        </main>
    </InisoftContainer>
</template>

<script lang="ts" setup>
    import { useTheme } from 'vuetify'
    const theme = useTheme()
    theme.global.name.value = 'defaultTheme'
</script>


<script lang="ts">
import { useAuthenticationStore } from '@/stores/authentication';

import ProductLogo from '@/components/shared/ProductLogoComponent.vue';
import ProductIconLogo from '@/components/shared/ProductIconLogoComponent.vue';
import InisoftTextInput from '@/components/forms/Inputs/InisoftTextInput.vue';
import InisoftForm from '@/components/forms/InisoftForm.vue';
import InisoftContainer from '@/components/layout/InisoftContainer.vue';

import FlowPatternHeaderRight from '@/components/shared/flows/FlowPatternHeaderRight.vue';
import FlowPatternHeaderRightWide from '@/components/shared/flows/FlowPatternHeaderRightWide.vue';
import FlowPatternBBTopLeft from '@/components/shared/flows/FlowPatternBBTopLeft.vue';
import FlowPatternBBBottomRight from '@/components/shared/flows/FlowPatternBBBottomRight.vue';
import FlowPatternBBTopRight from '@/components/shared/flows/FlowPatternBBTopRight.vue';
import FlowPatternBBBottomLeft from '@/components/shared/flows/FlowPatternBBBottomLeft.vue';

export default {
    props: ["tenant"],
    beforeMount() {
        this.authStore.setTenant(this.tenant)
    },
    components: {
        ProductLogo,
        ProductIconLogo,
        InisoftTextInput,
        InisoftForm,
        InisoftContainer,
        FlowPatternHeaderRight,
        FlowPatternHeaderRightWide,
        FlowPatternBBTopLeft,
        FlowPatternBBTopRight,
        FlowPatternBBBottomRight,
        FlowPatternBBBottomLeft
    },
    methods: {
        async logIn() {
            try {
                await this.authStore.logIn(this.username, this.password, 
                (response: any) => {
                    if (this.authStore.hasDirective("password-change")) {
                        this.$router.push({ path: '/password-change' });
                    } else if (response.responseStatus === 0) {
                        this.$router.push({ name: 'ViewConversations' });
                    } else if (response.responseStatus === 1) {
                        this.$router.push({ path: '/password-change' });
                    }
                },
                (error: any | null) => {
                    this.loginError(error)
                })
            } catch(error: any) {
                this.loginError(null)
            }
        },
        loginError(error: string | null) {
            let contactAdmin = error == null;
            let isCancel = false;
            const cancelError = this.$t('error.loginCancel');

            if (error == undefined || error == null) {
                error = this.$t('error.contactAdmin');
            } else {
                isCancel = error.includes("user_cancelled")
            }
            
            // @ts-ignore
            this.$swal({         
                title: contactAdmin ? this.$t('error.contactAdminTitle') : this.$t('error.loginTitle'),            
                html: isCancel? cancelError : error,
                customClass: {
                    container: 'i-alert i-alert--error',
                },
                confirmButtonText: `Close`,
                showClass: {
                    popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                    `
                },
                hideClass: {
                    popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                    `
                }
            });
        }
    },
    data() {
        return {
            passwordVisible: false,
            error: '',
            username: '',
            password: '',
            authStore: useAuthenticationStore()
        }
    }
}
</script>

<style lang="scss">
</style>
