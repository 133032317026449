import { useAuthenticationStore } from "@/stores/authentication";
import settings from "../settings/env-settings";
import resolve from "path";

export default new class Api {
  baseApiUrl: string = settings.getGatewayUrl();

  getApiEndpoint = (url: string): string => {return `${this.baseApiUrl}api/${url}`;}

  getRefreshRequestOptions = (abortSignal?: AbortSignal): RequestInit => {
    const options: RequestInit = {
      headers: {
      },
      method: "POST",
      mode: 'cors',
    };

    if (abortSignal) {
      options.signal = abortSignal;
    }

    const lc_securityStore = useAuthenticationStore();
    if (lc_securityStore.getTokens !== null) {
      const token = lc_securityStore.getTokens.refreshToken;
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
    }

    return options;
  };

  getBaseRequestOptions = (abortSignal?: AbortSignal): RequestInit => {
    const options: RequestInit = {
        headers: {
        },
        method: "GET",
        mode: 'cors',
    };

    if (abortSignal) {
        options.signal = abortSignal;
    }

  const lc_securityStore = useAuthenticationStore();
    if (lc_securityStore.getTokens !== null) {
      const token = lc_securityStore.getTokens.accessToken;
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${token}`,
        };
    }

    return options;
  };
  
  getPOSTRequestOptions = (body: any): RequestInit => {
      const options = this.getBaseRequestOptions();
  
      return {
          ...options,
          method: 'POST',
          headers: {
              ...options.headers,
        "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
      };
  };
  
  getPUTRequestOptions = (body: any): RequestInit => {
    const options = this.getBaseRequestOptions();
  
    return {
      ...options,
      method: 'PUT',
      headers: {
        ...options.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
  };

  getPATCHRequestOptions = (body: any): RequestInit => {
    const options = this.getBaseRequestOptions();
  
    return {
      ...options,
      method: 'PATCH',
      headers: {
        ...options.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
  };
  
  getDELETERequestOptions = (body: any): RequestInit => {
    const options = this.getBaseRequestOptions();
  
    return {
      ...options,
      method: 'DELETE',
      headers: {
        ...options.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
  };

  DeleteAsync = async (url: string, body: any): Promise<any> => {
    const endpoint = this.getApiEndpoint(url);
    
    const reqOptions = this.getDELETERequestOptions(body);
    
    await this.refreshToken();

    return fetch(endpoint, reqOptions);
  }

  PostAsync = async (url: string, body: any, onError: any = null, isAuth: boolean = false): Promise<any> => {
    let endpoint = null;

    if(!isAuth)
      await this.refreshToken();

    endpoint = this.getApiEndpoint(url);
    const reqOptions = this.getPOSTRequestOptions(body);
      
    return fetch(endpoint, reqOptions).catch(onError);
  }

  PutAsync = async (url: string, body: any): Promise<any> => {
      const endpoint = this.getApiEndpoint(url);
      
      const reqOptions = this.getPUTRequestOptions(body);
      
      await this.refreshToken();

      return fetch(endpoint, reqOptions);
  }

  PatchAsync = async (url: string, body: any): Promise<any> => {
    const endpoint = this.getApiEndpoint(url);
    
    const reqOptions = this.getPATCHRequestOptions(body);
    
    await this.refreshToken();

    return fetch(endpoint, reqOptions);
}

  GetAsync = async (url: string, body: any): Promise<any> => {
      const endpoint = this.getApiEndpoint(url);
      
      const reqOptions = this.getBaseRequestOptions();
      
      await this.refreshToken();

      return fetch(endpoint, reqOptions);
  }

  // Sign-in user //
  async signin(username: String, password: String, tenant: String) {
    const endpoint = this.getApiEndpoint('auth/authentication/authenticate');
    
    const requestData = {
      Username: username,
      Password: password,
      TenantDomain: tenant
    };

    const options: RequestInit = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      body: JSON.stringify(requestData),
    };

    try {
      const response = await fetch(endpoint, options);
      
      if (response.ok) {
        const json = response.json();
        return new Promise((resolve) => resolve(json))
      }

      return new Promise((resolve) => resolve(null))
    } catch { 
      return new Promise((resolve) => resolve(null))
    }
  }

  async signout(userId: String) {
    const requestData = {
      Id: userId,
    };

    return new Promise((resolve) => {
      this.PostAsync("auth/authentication/logout", requestData, () => {resolve(null)}, true).then((res) => res.json()).then(response => {
        resolve(response);
      }).catch(() => { resolve(null); })
    });
  }

  async validateToken(): Promise<boolean> {
    const endpoint = this.getApiEndpoint('auth/validate/checktoken');

    try {
      const response = await fetch(endpoint, this.getBaseRequestOptions(undefined));

      if (response.status > 201 || response.status < 200) {
        return false;
      }
      return true;
    } catch { /* empty */ }
    return false;
  }

  async refreshToken(): Promise<boolean> {
    const endpoint = this.getApiEndpoint('auth/authentication/refresh-token');
    const store = useAuthenticationStore()
    if(store.getTokenExpirySeconds() < 600) {
      try {
        const response = await fetch(endpoint, this.getRefreshRequestOptions(undefined));
        response.json().then((resp) => {
          store.setToken(resp)
        })
        return true;
      } catch { /* empty */ }
      return false;
    }
    return false
  }
  
  async login2fa(code: string): Promise<any> {
    const endpoint = this.getApiEndpoint('auth/authentication/twofactor')
    const reqOptions = this.getPUTRequestOptions({codeValue: code})
    return await fetch(endpoint, reqOptions)
  }

  
  async changeUserPassword(token: string, password: string): Promise<any> {
    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      mode: 'cors',
      body: JSON.stringify({newPassword: password})
    };

    const endpoint = this.getApiEndpoint('auth/recovery/password-recovery')
    return fetch(endpoint, options)
  }
}