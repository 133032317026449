import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import { useAuthenticationStore } from '@/stores/authentication'
import Runtime from '@/views/Runtime'
import Architect from '@/views/Architect'
import Login from '@/views/Login.vue'


// TODO add i18n for displayName metadata.

const sections = (Runtime as any[]).concat(Architect as any[])

const router = createRouter({
  scrollBehavior() {
    return { top: 0 }
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:tenant',
      name: 'root',
      props: true,
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login,
          meta: {
            displayName: "Login system",
            allowAnnonymous: true
          },
          props: true
        },
        { 
          path: 'app',
          name: 'app-root',
          component: HomeView,
          children: sections,
          props: {
            section: sections
          }
        },
        {
          path: 'password-reset',
          name: 'password-reset',
          component: () => import('../views/PasswordReset.vue'),
          meta: {
            displayName: "Password Reset",
            allowAnnonymous: true
          },
          props: true,
        },
        {
          path: 'password-change/:token',
          name: 'password-change',
          component: () => import('../views/PasswordChange.vue'),
          meta: {
            displayName: "Password Change",
            allowAnnonymous: true
          },
          props: true
        },
        {
          path: 'two-factor-auth',
          name: 'two-factor-auth',
          children: [
            {
              path: 'create',
              name: 'create-two-factor-auth',
              component: () => import('../views/CreateTwoFactorAuth.vue'),
              meta: {
                twoFactorSetupRoute: true,
                displayName: "Create Two Factor Auth",
              }
            },
            {
              path: 'verify',
              name: 'verify-two-factor-auth',
              component: () => import('../views/TwoFactorAuth.vue'),
              meta: {
                twoFactorLoginRoute: true,
                displayName: "Two Factor Auth",
              }
            }
          ]
        },
        // {
        //   path: 'my-profile',
        //   component: () => import('@/views/Shared/my-profile/MyProfile.vue'),
        //   name: 'MyProfile',
        //   meta: {
        //     displayName: "navigation.myProfile",
        //     icon: "$homeVariant",
        //   },
        // } 
      ]
    }
  ]
})

router.onError((error, to) => {
  console.log(error)
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location.replace(to.fullPath);
    }
  }
 
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

router.beforeEach(async (to, from, next) => {
  const store = useAuthenticationStore()
  console.log(to)
  console.log(from)
  if (to.name === 'login') {
    console.log("doot 1")
    next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
    return;
  }
  if (to.matched.some((record) => record.meta.allowAnnonymous)) {
    console.log("doot 2")
    // Allow proceeding to the page //
    next();
    return;
  }

  if (!store.isAuthenticated) {
    console.log("doot 3")
    next({name: "login"});
    return;
  }

  // Check user state requirement //
  if (store.hasDirective("password-change")) {
    console.log("doot 4")
    // Ensure password change route is selected //
    if (to.matched.some((record) => record.meta.passwordChangeRoute)) {
      next();
      return;
    } else {
      next("/password-change");
      return;
    }
  }

  if (store.hasDirective("two-factor-setup")) {
    console.log("doot 5")
    if (to.matched.some((record) => record.meta.twoFactorSetupRoute)) {
      console.log("doot 5-1")
      next();
      return;
    } else {
      console.log("doot 5-2")
      next({name: "create-two-factor-auth"});
      return;
    }
  }

  // Check two-factor login //
  if (store.hasDirective("two-factor")) {
    console.log("doot 6")
    if (to.matched.some((record) => record.meta.twoFactorLoginRoute)) {
      next();
      return;
    } else {
      next({name: "verify-two-factor-auth"});
      return;
    }
  }
  console.log("routing")
  next()
  return;
  // // Validate token //
  // if (!store.isTokenValid) {
  //   // Validate the token //
  //   if (await Api.validateToken()) {
  //     // Token is valid //
  //     store.setTokenValid(true);
  //     next();
  //   } else {
  //     // Invalid token //
  //     store.logOut();
  //     next("/login");
  //   }
  // } else {
  //   // All good, proceed //
  //   next();
  // }
})

export default router
